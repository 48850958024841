<template>
  <div class="content activity-data">
    <div class="worker-list">
      <fm-title title-text="成员情况" :title-menus="[{'key': 'addWorker', label: '新增'}]" @clickTitleMenu="chooseWorker = null;openDialogWorker=true"></fm-title>
      <div class="tb">
        <fm-table-new
          :columns="columnListWorker"
          size="large"
          border="row"
          :data-list="workerList"
          :stripe="false"
          emptyPlaceholder="-">
          <table-actions slot="actions" @table-action="tableAction2" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
        </fm-table-new>
      </div>
    </div>
    <fm-form-dialog
      form-title="成员情况"
      :open-dialog.sync="openDialogWorker"
      :form-parms="formParms2"
      :old-data="chooseWorker"
      :mask-closable="false"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit2"
      @handleClose="openDialogWorker = false">
    </fm-form-dialog>
  </div>
</template>

<script>
import TableActions from '@/components/base/TableActions'

import {
  localstorageHelper
} from '@/fmlib'

export default {
  components: {
    TableActions
  },
  props: {
    org: {
      type: Object
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tableActions: [{key: 'edit', label: '修改'}, {key: 'del', label: '删除'}],
      openDialogWorker: false,
      allWorkerList: [],
      workerList: [],
      chooseWorker: null,
      columnListWorker: [{
        field: 'workerName',
        title: '姓名'
      },
      {
        field: 'roleName',
        title: '职务'
      },
      {
        field: 'phone',
        title: '联系电话'
      },
      {
        title: '操作',
        field: 'actions',
        slot: 'actions', width: 100, fixed: 'right', search: false, export: false, configurable: false
      }]
    }
  },
  computed: {
    formParms2: {
      get () {
        let data = [{
          type: 'input',
          label: '成员姓名',
          key: 'workerName'
        },
        {
          type: 'input',
          label: '职务',
          key: 'roleName'
        },
        {
          type: 'input',
          label: '联系电话',
          key: 'phone'
        }]
        return data
      }
    }
  },
  created () {
    this.loadWorkers()
  },
  methods: {
    tableAction2 ({data, action}) {
      this.chooseWorker = data
      if (action === 'edit') {
        this.openDialogWorker = true
      } else if (action === 'del') {
        this.allWorkerList = this.allWorkerList.filter(v => v.id !== this.chooseWorker.id)
        localstorageHelper.updateData('orgWorkers', this.allWorkerList)
        this.loadWorkers()
      }
    },
    loadWorkers () {
      this.allWorkerList = localstorageHelper.getData('orgWorkers') || []
      this.workerList = this.allWorkerList.filter(v => v.orgId === this.org.id)
    },
    formSubmit2 (data) {
      let parm = Object.assign({}, data)
      parm.orgId = this.org.id
      if (!parm.id) {
        parm.id = new Date().getTime()
        this.allWorkerList.push(parm)
      } else {
        this.allWorkerList = this.allWorkerList.map(v => v.id === parm.id ? parm : v)
      }
      localstorageHelper.updateData('orgWorkers', this.allWorkerList)
      this.openDialogWorker = false
      this.loadWorkers()
    }
  }
}
</script>

<style scoped lang="less">
.activity-data {
  display: flex;
  .group-list {
    flex: 1;
    padding-right: 20px;
  }
  .worker-list {
    flex: 1;
  }
  .tb {
    min-height: 20rem;
  }
}
</style>
