<template>
  <div class="content activity-data">
    <fm-form :inline="2" label-alone label-align="left" style="position: relative;">
      <fm-form-item label="党组织名称">
        <fm-input-new placeholder="名称" v-model="orgData.name" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="成立时间">
        <fm-date-picker placeholder="成立时间" v-model="otherInfo.clsj" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="党组织的信息" style="width: 100%;">
        <fm-input-new style="height: 10rem;" type="textarea" placeholder="党组织的信息" v-model="otherInfo.zzjj" :disabled="readOnly"/>
      </fm-form-item>
    </fm-form>
  </div>
</template>

<script>
import {
  dateOperating
} from '@/fmlib'

export default {
  components: {},
  props: {
    sourceData: {
      type: Object
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      orgData: {},
      otherInfo: {}
    }
  },
  watch: {
    sourceData: {
      handler () {
        let data = JSON.parse(JSON.stringify(this.sourceData))
        this.otherInfo = data && data.description ? JSON.parse(data.description) : {}
        this.orgData = data
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    fileChange (data) {
      this.otherInfo.coverFileId = data.id
    },
    logoFileChange (data) {
      this.otherInfo.logoFileId = data.id
    },
    getFormData () {
      let data = Object.assign({}, this.orgData)
      this.otherInfo.clsj = this.otherInfo.clsj ? dateOperating.computeDay({days: 0, date: this.otherInfo.clsj, format: 'yy-mm-dd hh:mm:ss'}) : null
      data.description = JSON.stringify(this.otherInfo)
      return data
    }
  }
}
</script>

<style scoped lang="less">
.activity-data {
  .fm-form-inline {
    padding: 0 0 0 16px !important;
  }
  .form-item-f {
    width: 100% !important;
  }
}
</style>
